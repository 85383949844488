 .menu-btn {
     /* text-transform: capitalize;
    --background: #FFFFFF;
    --box-shadow: none;
    --color: black;
    margin: 0px;
    font-size: 1vw;
    font-weight: bold;
    --padding: 10px 0;
    width: 100%;
    --border-radius: none;
    border-bottom: 1px solid #e8e8e8;
    text-align: left;
    height: 40px; */
     color: #404040;

     font-size: 23px;
     font-style: normal;
     font-weight: 700;
     line-height: 64px;
     width: 100%;
     cursor: pointer;
     border-bottom: 1px solid #000;
 }

 .menu-items:last-child .menu-btn {
     border: none;
 }

 
 .selected-btn {
     /* text-transform: capitalize;
    --background: #ECA74F !important;
    --box-shadow: none;
    --color: white;
    margin: 0px;
    font-size: 1vw;
    font-weight: bold;
    width: 100%;
    --border-radius: none;
    border-bottom: 1px solid #e8e8e8;
    text-align: left; */

     color: var(--ion-color-primary);

     font-size: 23px;
     font-style: normal;
     font-weight: 700;
     line-height: 64px;
     width: 100%;
     cursor: pointer;
     border-bottom: 1px solid #000;
 }

 .selected-btn-materials {
     text-transform: capitalize;
     --background: #F6CC89;
     --box-shadow: none;
     --color: black;
     margin: 0px;
     font-size: 1vw;
     font-weight: bold;
     width: 100%;
     --border-radius: none;
     border: 1px solid #F6BD64;
     text-align: left;
 }

 .menu-btn-materials {
     text-transform: capitalize;
     --background: #FFFFFF;
     --box-shadow: none;
     --color: black;
     margin: 0px;
     font-size: 1vw;
     font-weight: bold;
     --padding: 10px 0;
     width: 100%;
     --border-radius: none;
     border: 1px solid #F6BD64;
     text-align: left;
 }

 .selected-btn-offerings {
     text-transform: capitalize;
     --background: #F6CC89;
     --box-shadow: none;
     --color: black;
     margin: 0px;
     font-size: 1vw;
     font-weight: bold;
     width: 100%;
     --border-radius: none;
     border: 1px solid #F6BD64;
     text-align: left;
 }

 .menu-btn-offerings {
     text-transform: capitalize;
     --background: #FFFFFF;
     --box-shadow: none;
     --color: black;
     margin: 0px;
     font-size: 1vw;
     font-weight: bold;
     --padding: 10px 0;
     width: 100%;
     --border-radius: none;
     border: 1px solid #F6BD64;
     text-align: left;
 }

 .containerStyle {
     /* width: 700px; */
     height: 600px
 }

 /* @media only screen and (max-width:782px){
    .containerStyle{
        width: 700px;
        height:300px
    }
}

@media only screen and (max-width:550px){
    .containerStyle{
        width: 500px;
        height:400px
    }
}

@media only screen and (min-width:450px){
    .containerStyle{
        width: 350px;
        height:400px
    }
}

@media only screen and (max-width:400px){
    .containerStyle{
        width: 680px;
        height:400px
    }
}

@media only screen and (max-width:398px){
    .containerStyle{
        width: 380px;
        height:400px
    }
} */
 .templeImage {
     float: right;
     clear: both;
     width: 30%;
     margin: 10px 10px 10px 10px
 }


 .remove-border {
     --border-width: 0px 0px 0px 0px;
     font-size: 15px;
 }

 .remove-border:host {
     --highlight-color-focused: none
 }

 .menu-col {
     /* border: 0.5px solid #F6BD64; */
     padding: 0px;

 }

 .inputs {
     --padding-top: 0px !important;
     --padding-bottom: 0px !important;
     font-size: 14px;
     height: 30px;
 }

 .details-col {
     padding-left: 0%;

     padding-top: 50px;
     padding-bottom: 0%;
     padding-right: 0%;
     /* border: 0.5px solid #e9d5cf;
    min-height: 400px; */
     overflow-x: auto;
     background: var(--ion-temple-bg);
     /* border-bottom: 1px solid #F6BD64;
    border-right: 1px solid #F6BD64;
    overflow-y: scroll;
    height: 255px; */
 }

 .divider {
     border-left: 1px solid #576F9F;
 }

 .justify-text {
     align-self: center;
 }

 .table_column {
     margin-left: 10%;
 }

 .row_head {
     border-top-left-radius: 7px;
     border-top-right-radius: 7px;
     box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.04);

     background: var(--ion-bold-text-color);
     color: #FFF;
     ;
     font-size: 23px;
     font-style: normal;
     font-weight: 700;
     line-height: 30px;
     padding: 10px 10px;
 }

 .row_col {

     background: #FFF;

 }

 .row_first {
     padding-left: 20px !important;
 }

 .table_container {
     margin: 30px 30px;





 }

 .col_border {

     /* border-width: 2px !important;
    border-color: #576F9F !important; */
     border-right: 1px solid #E8E8E8 !important;

     padding: 10px 5px;
     font-size: 21px;
     font-style: normal;

     line-height: 30px;
     color: #484848;
     ;
 }

 .pooja_card {
     padding: 10px;
 }

 .pooja_col {


     padding: 5px 5px;
     font-size: 20px;
     font-style: normal;


     color: #484848;
     ;
 }

 .col_last {

     /* border-width: 2px !important;
    border-color: #576F9F !important; */

     padding: 10px 5px;
     font-size: 21px;
     font-style: normal;

     line-height: 30px;
     color: #484848;
     ;
 }

 .rightImage:before {
     content: '';
     display: block;
     float: right;
     height: 200px;

 }

 .rightInfoIcon:before {
     content: '';
     display: block;
     float: right;
 }

 .head-border {

     border: none;
 }

 .timings {
     width: 90%;
 }

 .timings .col_border {
     background-color: white;
     border-width: 2px !important;
     border-color: #EE6E45 !important;
 }

 .timings .head-border {
     border-style: solid;
     border-width: 2px !important;
     border-color: #EE6E45 !important;
     background-color: #FFFFFF !important;
 }

 .img {
     height: 150px;
     padding: 10px;
 }

 .img-card {
     padding: 5px;
     background-color: white !important;
     color: white;
 }

 .poojaRow td,
 tr {
     border: 0px solid #dbdbdb !important;
     text-align: left;
     cursor: pointer;
 }

 @media only screen and (max-width:991px) {
     .menu-items {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 100%;
     }

     .menu-btn,
     .selected-btn {
         font-size: 1.5vw !important;

     }

     .menu-items-materials {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 20%;
     }

     .menu-items-offerings {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 30%;
     }

     .head-border {

         font-size: 16px;
         border: none;
     }
 }

 @media only screen and (max-width:680px) {

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 9px !important;
     }

     .table_container {
         margin: 5px;

     }

     .head-border {

         font-size: 16px;
         border: none;
     }
 }

 @media only screen and (max-width:767px) {

     .menu-btn,
     .selected-btn {
         font-size: 2vw !important;
     }

     .remove-border {
         font-size: 2vw;
     }

     .divider {
         border-left: none;
     }

     .details-col {
         height: 100vh;
     }

     .table_container {
         margin: 20px;

     }

     .head-border {

         font-size: 16px;
         border: none;
     }
 }

 @media only screen and (max-width:500px) {

     .menu-btn,
     .selected-btn {
         font-size: 2.5vw !important;
     }

     .remove-border {
         font-size: 3vw;
     }

     .table_container {
         margin: 5px;

     }

     .head-border {

         font-size: 16px;
         border: none;
     }
 }

 @media only screen and (max-width:400px) {

     .menu-btn,
     .selected-btn {
         font-size: 3vw !important;
     }

     .remove-border {
         font-size: 4vw;
     }

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 10px !important;
     }

     .table_container {
         margin: 5px;

     }
 }

 @media only screen and (max-width:460px) {
     .menu-items-materials {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 25%;
     }

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 8px !important;
     }

     .table_container {
         margin: 5px;

     }
 }

 @media only screen and (max-width:426px) {
     .menu-items-materials {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 25%;
     }

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 9px !important;
     }

     .table_container {
         margin: 5px;

     }
 }

 @media only screen and (max-width:380px) {
     .menu-items-materials {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 30%;
     }

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 9px !important;
     }

     .table_container {
         margin: 5px;

     }
 }

 @media only screen and (max-width:440px) {
     .menu-items-offerings {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 28%;
     }

     .menu-btn-offerings,
     .selected-btn-offerings {
         font-size: 9px !important;
     }

     .table_container {
         margin: 5px;

     }
 }

 @media only screen and (max-width:338px) {

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 8px !important;
     }

     .menu-btn-offerings,
     .selected-btn-offerings {
         font-size: 8px !important;
     }
 }

 @media only screen and (max-width:320px) {
     .menu-items-offerings {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 33%;
     }

     .menu-btn-offerings,
     .selected-btn-offerings {
         font-size: 9px !important;
     }

     .menu-items-materials {
         display: inline-block;
         vertical-align: top;
         box-sizing: border-box;
         width: 33%;
     }

     .menu-btn-materials,
     .selected-btn-materials {
         font-size: 9px !important;
     }
 }

 @media only screen and (min-width:767px) {
     .menu_show {
         display: block;

         border-right: none;
         border-radius: 4px 0px 0px 4px;
         padding: 0px;
         height: 100%;
     }
 }

 @media only screen and (max-width:767px) {
     .menu_show {
         display: none;

         padding: 0px;
         height: 100%;
     }
 }

 @media only screen and (max-width:767px) {
     .menu_pop {
         display: block;

         color: var(--ion-bold-text-color);

     }

     .pooja_tab {
         display: none;
     }

     .details-col {
         padding-top: 20px;
     }
 }

 @media only screen and (min-width:767px) {
     .menu_pop {
         display: none;

         color: var(--ion-bold-text-color);


     }

     .pooja_tab {
         display: block;
     }
 }

 @media only screen and (max-width:700px) {
     .templeImage {
         width: 100%;
         float: none;
         clear: both;
         height: 300px;
         margin: 10px 10px 10px 10px
     }

     .rightImage:before {
         content: '';
         display: block;
         float: none;
         height: 200px;

     }
 }

 .menu_pop_icon {

     font-size: large;
     color: var(--ion-bold-text-color);

 }

 /* @media only screen and (max-width:400px) {
    .menu_show{
     display: block;
     border: 0.5px solid #F6BD64;
     padding: 0px;
     height: 100%;
    }
} */
 .templeTabBtnMainCls {
     padding: 10px;
     background: white !important;
     overflow-y: hidden;
     overflow: auto;
     height: 300px;



 }

 .templeTabBtnMainCls ion-button {
     width: 100%;
     margin: 0px;
     text-transform: capitalize;
     --background: #FFFFFF;
     --box-shadow: none;
     --color: black;
     margin: 0px;
     font-size: 1vw;
     font-weight: bold;
     --padding: 10px 0;
     width: 100%;
     --border-radius: none;
     border-bottom: 1px solid #e8e8e8;
     text-align: left;
     height: 40px;
 }

 .wrapped-textarea {
     white-space: normal !important;
     word-wrap: break-word;
     overflow-wrap: break-word;
     border: 1px solid #F6BD64;
     margin: 10px;
     padding: auto;
     height: auto;
     overflow-y: auto;

 }


 /* new UI */

 .deity_cards {
     background-color: #F3F3F3;
     border-radius: 10px;
     margin: 0px;
     padding: 10px;
 }

 .deity_container {
     padding-left: 30px;
     padding-right: 30px;
 }

 .MainDeity {
     height: fit-content !important;
 }

 .deity_description span {
     font-weight: 900 !important;
 }

 .deity_description {
     color: #404040;
     font-size: 14px;
     font-weight: 400;
     line-height: 23px;
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 3;
     -webkit-box-orient: vertical;
     cursor: pointer;
     text-align: left;
 }

 .deity_name {
     color: #404040;
     font-size: 20px;
     font-style: normal;
     font-weight: 700;
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     cursor: pointer;
 }

 .deity_god_image {
     width: 200px;
     height: 170px;
 }

 .info_icon {
     width: 30px;
     height: 30px;
     cursor: pointer;
     color: var(--ion-color-primary);
 }

 .area_btn {
     --border-radius: 49px;
 }

 .area_btn_text {
     color: #FFF;
     font-size: 17px;
     font-style: normal;
     font-weight: 700;
     line-height: 23px;
     padding: 25px;
 }

 @media only screen and (max-width:1200px) {
     .area_btn_text {
         color: #FFF;
         font-size: 15px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
         padding: 15px;
     }
 }

 @media only screen and (max-width:1020px) {
     .area_btn_text {
         color: #FFF;
         font-size: 13px;
         font-style: normal;
         font-weight: 700;
         line-height: 15px;
         padding: 10px;
     }
 }

 @media only screen and (max-width:900px) {
     .area_btn_text {
         color: #FFF;
         font-size: 13px;
         font-style: normal;
         font-weight: 700;
         line-height: 15px;
         padding: 5px;
     }
 }

 @media only screen and (max-width:840px) {
     .deity_container {
         padding-left: 25px;
         padding-right: 25px;
     }

     .deity_name {
         color: #404040;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-line-clamp: 1;
         -webkit-box-orient: vertical;
         cursor: pointer;
     }

     .area_btn_text {
         color: #FFF;
         font-size: 12px;
         font-style: normal;
         font-weight: 700;
         line-height: 15px;
         padding: 0px;
     }
 }

 @media only screen and (max-width:766px) {
     .area_btn_text {
         color: #FFF;
         font-size: 17px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
         padding: 25px;
     }
 }

 @media only screen and (max-width:430px) {
     .area_btn_text {
         color: #FFF;
         font-size: 15px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
         padding: 20px;
     }
 }

 @media only screen and (max-width:390px) {
     .area_btn_text {
         color: #FFF;
         font-size: 13px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
         padding: 12px;
     }

     .info_icon {
         width: 20px;
         height: 20px;
         cursor: pointer;
         color: var(--ion-color-primary);
     }

     .deity_name {
         color: #404040;
         font-size: 18px;
         font-style: normal;
         font-weight: 700;
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-line-clamp: 1;
         -webkit-box-orient: vertical;
         cursor: pointer;
     }
 }

 @media only screen and (max-width:500px) {
     .deity_container {
         padding-left: 10px;
         padding-right: 10px;
     }
 }

 @media only screen and (max-width:390px) {
     .deity_container {
         padding-left: 5px;
         padding-right: 5px;
     }

     .area_btn_text {
         color: #FFF;
         font-size: 13px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
         padding: 0px;
     }
 }

 .about_deity_container {
     background: var(--ion-content-background-color) !important;
     padding: 20px;
 }

 .bio_deity {
     color: var(--ion-bold-text-color);
     font-size: 22px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     letter-spacing: 2px;

 }

 .bio_deity_image {
     float: right;
     clear: both;
     width: 20%;
     /* margin: 5px 5px 5px 5px */
 }

 .bio_right_img {
     content: '';
     display: block;
     float: right;
     height: 400px;
     width: 450px;
 }

 .bio_deity_description {
     color: #404040;
     font-size: 23px;
     font-style: normal;
     font-weight: 400;
     line-height: 43px;
     text-align: justify;
     padding: 25px;
 }

 @media only screen and (max-width:720px) {
     .bio_deity_description {
         color: #404040;
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: 40px;
         text-align: justify;
     }

     .bio_deity_image {
         width: 100%;
         float: none;
         clear: both;
         /* margin: 10px 10px 10px 10px */
     }

     .bio_right_img {
         content: '';
         display: block;
         float: none;
         height: 250px;

     }
 }

 .custom-modal-wrapper {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100%;
 }

 @media only screen and (max-width:440px) {
     .about_deity_container {
         background: #F1FAFF;
         padding: 20px;
     }
 }

 .custom-modal-wrapper .modal-dialog {
     margin-top: 170px !important;
     min-width: 400px !important;
     max-width: 550px !important;
 }

 @media only screen and (max-width: 900px) {
     .custom-modal-wrapper .modal-dialog {
         margin-top: 120px !important;
         min-width: 376px !important;
     }

     .about_deity_container {
         background: #F1FAFF;
         padding: 20px;
     }
 }

 @media only screen and (max-width: 573px) {
     .custom-modal-wrapper .modal-dialog {
         margin-top: 70px !important;
         min-width: 376px !important;
     }

     .custom-modal-wrapper {
         display: flex !important;
         justify-content: center;
         align-items: normal;
         height: 100%;
     }
 }

 @media only screen and (max-width: 400px) {
     .custom-modal-wrapper .modal-dialog {
         margin-top: 70px !important;
         min-width: 306px !important;
     }
 }

 .modal_container {
     background-color: #FFF;
     border-radius: 52px;
     /* padding: 30px; */
 }

 .area_modal_head {
     color: #898989;
     font-size: 20px;
     font-style: normal;
     font-weight: 400;
 }

 .modal_close_icon {
     width: 16px;
     height: 16px;
     cursor: pointer;
 }

 .area_modal_subHead {
     color: #576F9F;
     font-size: 25px;
     font-style: normal;
     font-weight: 700;
 }

 .area_modal_labels {
     color: #484848;
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
 }

 /* offring page css */

 .off_main_container {
     padding: 0px 30px !important;
     background: var(--ion-content-background-color) !important;
 }

 .offering_temple_name {
     color: var(--ion-bold-text-color);
     font-size: 20px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     letter-spacing: 2px;
 }

 .searchBox-element {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     width: 100%;
     padding: 10px 0px;
 }

 .off_temple_image {
     width: 100px;
     height: 100px;
 }

 .off_deity_dropdown {
     width: 300px;
     height: 68px;
     background-color: white;
     border-radius: 8px;

     color: #404040;
     font-size: 26px;
     font-weight: 700;
     line-height: 64px;
     padding-left: 23px;
 }

 .off_card_container {
     border-radius: 14px;
     background-color: #FFF;
     padding: 15px;
 }

 .accept_logo {
     width: 20px;
     height: 20px;
 }

 .offering_name {
     color: #404040;
     font-size: 22px;
     font-style: normal;
     font-weight: 700;
     line-height: 43px;
 }

 .card_label {
     color: #404040;
     font-size: 18px;
     font-style: normal;

     line-height: 43px;
 }

 .price_style {
     color: #576F9F;
     font-size: 22px;
     font-style: normal;
     font-weight: 700;
     line-height: 43px;
 }

 @media only screen and (max-width:1160px) {
     .offering_temple_name {
         color: var(--ion-bold-text-color);
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 2px;
     }
 }

 @media only screen and (max-width:1000px) {
     .offering_temple_name {
         color: var(--ion-bold-text-color);
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 2px;
     }
 }


 @media only screen and (max-width:900px) {
     .card_label {
         color: #404040;
         font-size: 18px;
         font-style: normal;

         line-height: 33px;
     }

     .price_style {
         color: #576F9F;
         font-size: 24px;
         font-style: normal;
         font-weight: 700;
         line-height: 33px;
     }
 }

 @media only screen and (max-width:550px) {
     .offering_temple_name {
         color: var(--ion-bold-text-color);
         font-size: 15px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 2px;
     }
 }

 @media only screen and (max-width:500px) {
     .off_main_container {
         padding: 20px;
         background: var(--ion-content-background-color) !important;
     }
 }

 /* @media only screen and (max-width:800px) {
     .off_main_container {
         /* height: 100vh; 
     }
 } */

 @media only screen and (max-width:450px) {
     .card_label {
         color: #404040;
         font-size: 15px;
         font-style: normal;

         line-height: 23px;
     }

     .price_style {
         color: #576F9F;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: 23px;
     }

     .off_card_container {
         border-radius: 14px;
         background-color: #FFF;
         padding: 20px;
     }

     .accept_logo {
         width: 15px;
         height: 15px;
     }

     .off_main_container {
         padding: 10px;
         background: var(--ion-content-background-color) !important;
     }
 }

 @media only screen and (max-width:400px) {
     .offering_temple_name {
         color: var(--ion-bold-text-color);
         font-size: 16px;
         font-style: normal;
         font-weight: 600;
         line-height: normal;
         letter-spacing: 2px;
         padding-left: 10px;
     }
 }

 @media only screen and (max-width:374px) {
     .offering_temple_name {
         color: var(--ion-bold-text-color);
         font-size: 15px;
         font-style: normal;
         font-weight: 600;
         line-height: normal;
         letter-spacing: 2px;
         padding-left: 7px;
     }
 }

 @media only screen and (max-width:358px) {
     .off_deity_dropdown {
         width: 270px;
         height: 68px;

         border-radius: 8px;
         background-color: white;

         color: #404040;
         font-size: 23px;
         font-weight: 700;
         line-height: 64px;
         padding-left: 20px;
     }
 }

 @media only screen and (max-width:350px) {
     .card_label {
         color: #404040;
         font-size: 13px;
         font-style: normal;

         line-height: 20px;
     }

     .price_style {
         color: #576F9F;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: 20px;
     }

     .off_card_container {
         border-radius: 14px;
         background-color: #FFF;
         padding: 10px;
     }

     .accept_logo {
         width: 15px;
         height: 15px;
     }

     .off_main_container {
         padding: 10px;
         background: var(--ion-content-background-color) !important;
     }
 }


 .row_container {
     display: flex;
     align-items: center;
 }

 .label_container {
     display: flex;
     justify-content: left;
     padding-left: 10px;
 }

 .label_container_info {
     display: flex;
     justify-content: flex-start;
     padding-left: 10px;
 }

 .off_book_btn {
     height: 62px;
 }

 .off_colon_display {
     display: flex;
 }

 @media only screen and (max-width:575px) {
     .off_colon_display {
         display: none;
     }
 }

 .off_book_btn_text {
     padding: 25px;
     color: #FFF;

     font-style: normal;
     font-weight: 700;
     line-height: 43px;
 }

 .info_modal_offname {
     color: #404040;
     font-size: 25px;
     font-style: normal;
     font-weight: 700;
     line-height: 45px;
 }

 .off_info_modal_header {
     display: flex;
     align-items: center;
 }

 @media only screen and (max-width:400px) {
     .off_info_modal .modal-content {
         width: 100%;
         margin-left: 0%;
         margin-right: 0%;
     }

 }

 .off_modal_label {
     color: #404040;
     font-size: 20px;
     font-style: normal;

     line-height: 43px;
 }

 .info_modal_accept_logo {
     width: 20px;
     height: 20px;
 }

 @media only screen and (max-width:900px) {
     .off_book_btn {
         height: 42px;
     }

     .off_book_btn_text {
         padding: 25px;
         color: #FFF;

         font-style: normal;
         font-weight: 700;
         line-height: 43px;
     }
 }

 @media only screen and (max-width:850px) {
     .info_modal_offname {
         color: #404040;
         font-size: 30px;
         font-style: normal;
         font-weight: 700;
         line-height: 64px;
     }
 }

 @media only screen and (max-width:480px) {
     .label_container {
         display: flex;
         justify-content: left;
         padding-left: 15px;
     }
 }

 @media only screen and (max-width:530px) {
     .off_modal_label {
         color: #404040;
         font-size: 17px;
         font-style: normal;

         line-height: 43px;
     }
 }


 @media only screen and (max-width:478px) {
     .info_modal_offname {
         color: #404040;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: 64px;
     }

 }

 @media only screen and (max-width:440px) {
     .off_modal_label {
         color: #404040;
         font-size: 13px;
         font-style: normal;

         line-height: 23px;
     }

     .info_modal_accept_logo {
         width: 15px;
         height: 15px;
     }
 }

 @media only screen and (max-width:350px) {
     .info_modal_accept_logo {
         width: 12px;
         height: 12px;
     }

     .info_modal_offname {
         color: #404040;
         font-size: 18px;
         font-style: normal;
         font-weight: 700;
         line-height: 64px;
     }

     .off_modal_label {
         color: #404040;
         font-size: 10px;
         font-style: normal;

         line-height: 23px;
     }
 }

 .book_details_main_container {
     background-color: var(--ion-content-background-color) !important;
 }

 .book_details_sub_container {
     padding: 20px;
     padding-left: 70px;
     padding-right: 70px;
     padding-bottom: 70px;
 }

 .book_details_head {
     font-size: 25px;
     font-weight: 700;
     line-height: 44px;
     letter-spacing: 2px;
     text-align: left;
     color: var(--ion-bold-text-color);
 }

 .book_content_container {
     border-radius: 7px 7px 0px 0px;
     margin-top: 50px;
 }

 .book_subcontent_container {
     background-color: #FFF;
     border-radius: 0px 0px 7px 7px;
 }

 .confrom_book_bg {
     background-color: #FFF;
     border-radius: 0px 0px 7px 7px;
 }

 .book_poojaname_row {
     background-color: #DBDBDB;
     width: 100%;
     border-radius: 7px 7px 0px 0px;
 }

 .book_poojaname_head {
     color: #222;
     ;
     font-size: 25px;
     font-style: normal;
     font-weight: 700;
     line-height: 43px;
     padding: 10px;
     padding-left: 30px;
 }

 .book_subcontent_container {
     padding-left: 30px;
     padding: 20px;
 }

 .book_details_label {
     color: #484848;
     ;
     font-size: 21px;
     font-style: normal;
     font-weight: 700;
     line-height: normal
 }

 .book_details_text {
     color: #484848;
     ;
     font-size: 21px;
     font-style: normal;
     font-weight: 700;
     line-height: 25px;
 }

 .label_row_container {
     display: flex;
     align-items: center;
     /* margin-top: 10px; */
 }

 .poojaBooking-modal .off_input {
     width: 540px !important;
 }

 .off_input {
     border-radius: 8px;
     background: #FFF;
 }

 .off_textarea {
     border-radius: 8px;
     background: #FFF;
     height: 60px;
     overflow-y: auto;
     max-width: 524px;
 }

 .select-stars * {
     z-index: 1000 !important;
 }

 .from_date_icon {
     margin-top: 15px;
 }

 .book_details_label_infoget {
     color: #484848;
     ;
     font-size: 21px;
     font-style: normal;

     line-height: normal
 }

 .book_details_label_date {
     color: darkgray;
     ;
     font-size: 18px;
     font-style: normal;

     line-height: normal
 }

 .off_date_container {
     padding-left: 0px;
 }

 .off_sdate_container {
     padding-left: 0px;
 }

 .accept_logo_off_info {
     width: 18px;
     height: 18px;
 }

 .date_font {
     font-size: 15px;
 }

 .off_month_container {
     padding: 0px;
     padding-left: 40px;
 }

 .sdate_label {
     text-align: center;
 }

 .radio_container {
     padding-left: 0px;
 }

 .month_decrement_btn {
     cursor: pointer;
     color: #484848;
 }

 .loginbtn_container_width {
     width: 30%;
 }

 .signupbtn_container_width {
     width: 35%;
 }

 .loginphonebtn_container_width {
     width: 39%;
 }


 /* New Css V2 */
 .image-description-container {
     display: flex;
     align-items: center;
     margin: 16px 0;
 }

 .temple-image {
     width: 150px;
     height: 150px;
     object-fit: cover;
 }

 .description-container {
     text-align: center;
     /* max-width: 500px; */
     /* margin: 0 20px; */
 }

 .temple_tab_container {
     border: 1px solid #DCDCDC;
     border-bottom: 0px !important;
 }

 .temple_tab {
     background-color: var(--ion-color-primary);
     padding: 10px;

 }

 .temple_tab_container:first-child {
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
 }

 .label_icon {
     display: flex;
     align-items: center;
     cursor: pointer;
 }

 .active_tab_container {
     border-bottom: 1px solid #DCDCDC;
     padding: 10px;
 }

 .temple_active_tab_container {
     padding: 0px !important;
 }

 .temple_tab_container:last-child {
     border-bottom: 1px solid #DCDCDC !important;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
 }

 .tab_content_container {
     padding: 20px;
     background-color: white !important;
 }


 .label_icon ion-icon {
     color: white;
     font-size: 25px;
 }

 .label_icon ion-label {
     padding-left: 10px;
     color: white;
 }

 .tab_parent_div {
     margin-bottom: 10px;
     text-align: left;
 }

 .img_centered {
     display: flex;
     justify-content: center;
 }

 .temple-name {
     font-size: 1.5rem;
     font-weight: bold;
     margin-bottom: 8px;
 }

 .temple-description {
     font-size: 1rem;
     color: #555;
 }

 /* ________ */


 /* ____V2 css____ */

 .tableContainev2 {
     padding: 10px 80px !important;
     padding-bottom: 0px !important;
 }

 @media only screen and (max-width : 550px) {
     .tableContainev2 {
         padding: 50px 30px !important;
     }
 }

 @media only screen and (max-width : 380px) {
     .tableContainev2 {
         padding: 50px 10px !important;
     }
 }

 .radio-search-container {
     display: flex;
     justify-content: flex-end;
     align-items: center;
     gap: 10px;
     flex-wrap: wrap;
     min-height: fit-content !important;
 }

 .select-group {
     display: flex;
     flex-direction: column;
     margin-right: 10px;
 }

 .select-label {
     margin-bottom: 4px;
     font-size: 14px;
     color: #555;
 }

 .radio-buttons {
     display: flex;
     gap: 10px;
     align-items: center;
 }

 .radio-buttons label {
     display: flex;
     align-items: center;
     gap: 5px;
 }

 .search-icon {
     font-size: 25px;
     cursor: pointer;
     background-color: var(--ion-color-primary);
     color: white;
     padding: 10px;
     border-radius: 4px;
 }

 .search-box {
     min-width: 200px;
     height: 40px;
 }

 .search-textbox {
     width: 400px;
     height: 40px;
     text-align: left;
 }

 .search-textbox-pooja {
     max-width: 400px;
 }

 .toolbar-container {
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 10px;
     padding: 10px 0px;

 }

 .view-toggle {
     display: flex;
     /* gap: 15px; */
     background-color: #fff;
     border: 1px solid var(--ion-color-primary);
     width: fit-content;
 }

 .toggle-icon {
     font-size: 20px;
     cursor: pointer;
     color: #666;
     padding: 5px;
 }

 .toggle-icon.active {
     background-color: var(--ion-color-primary);
     color: #fff;
 }






 .back {
     font-size: 30px;
 }


 .confrom_book_subcontent_container {
     padding-left: 30px;
     padding: 20px;
 }

 .book_details_label_info_check {
     color: #484848;
     ;
     font-size: 21px;
     font-style: normal;

     line-height: normal
 }

 .confrom_book_subcontent_container {
     padding-left: 30px;
     padding: 20px;
 }

 .confrom_book_details_sub_container {
     padding-left: 70px;
     padding-right: 70px;
     padding-bottom: 70px;
 }

 .save_book_btn {
     display: flex;
     justify-content: right;
 }

 .payment_btn {
     display: flex;
     justify-content: left;
 }



 @media only screen and (max-width:2652px) {
     .off_textarea {
         border-radius: 8px;

         background: #FFF;
         height: 60px;
         overflow-y: auto;
         max-width: 540px;
     }
 }



 @media only screen and (max-width:1100px) {
     .book_details_sub_container {
         padding: 20px;

         padding-left: 40px;
         padding-right: 40px;
         padding-bottom: 50px;
     }

     .book_details_label_infoget {
         color: #484848;
         ;
         font-size: 19px;
         font-style: normal;

         line-height: normal
     }

     .book_details_label_date {
         color: darkgray;
         ;
         font-size: 15px;
         font-style: normal;

         line-height: normal
     }

     .off_date_container {
         padding-left: 16px;
     }

     .off_month_container {
         padding: 16px;
         padding-left: 40px;
     }

     .off_sdate_container {
         padding-left: 16px;
     }
 }

 @media only screen and (max-width:1050px) {
     .book_details_sub_container {
         padding: 20px;

         padding-left: 20px;
         padding-right: 20px;
         padding-bottom: 50px;
     }

     .book_details_label_infoget {
         color: #484848;
         ;
         font-size: 19px;
         font-style: normal;

         line-height: normal
     }

     .book_details_label_date {
         color: darkgray;
         ;
         font-size: 17px;
         font-style: normal;

         line-height: normal
     }

     .off_date_container {
         padding-left: 10px;
     }

     .off_month_container {
         padding: 10px;
         padding-left: 40px;
     }

     .off_sdate_container {
         padding-left: 10px;
     }

     .accept_logo_off_info {
         width: 18px;
         height: 18px;
     }

     .book_subcontent_container {
         padding-left: 0px;
         padding: 10px;
     }

     .poojaBooking-modal .off_input {
         width: 100% !important;
     }
 }

 @media only screen and (max-width:990px) {
     .book_details_sub_container {

         padding-bottom: 40px;

         padding-left: 30px;
         padding-right: 30px;
         padding-bottom: 30px;
     }

     .book_details_label_infoget {
         color: #484848;
         ;
         font-size: 20px;
         font-style: normal;

         line-height: normal
     }

     .book_details_label_date {
         color: darkgray;
         ;
         font-size: 19px;
         font-style: normal;

         line-height: normal
     }

     .off_date_container {
         padding-left: 70px;
         text-align: center;
     }

     .off_sdate_container {
         padding-left: 44px;
     }

     .off_month_container {
         padding-left: 45px;
         text-align: center;
     }

     .accept_logo_off_info {
         width: 18px;
         height: 18px;
     }

     .book_subcontent_container {
         padding-left: 0px;
         padding: 10px;
     }
 }









 @media only screen and (max-width:900px) {
     .book_details_label_info_check {
         color: #484848;
         ;
         font-size: 17px;
         font-style: normal;

         line-height: normal
     }
 }

 @media only screen and (max-width:850px) {
     .login_alert_modal .modal-content {
         width: 60%;
         margin-left: 20%;
         margin-right: 20%;
         background-color: #F7F7F1;
     }
 }

 @media only screen and (max-width:830px) {
     .toolbar-container {
         display: block;
     }

     .view-toggle {
         margin-bottom: 10px;
     }
 }

 @media only screen and (max-width:767px) {
     .save_book_btn {
         display: flex;
         justify-content: center;
     }

     .searchBox-element {
         justify-content: center;
         padding-top: 20px;
     }

     .payment_btn {
         display: flex;
         justify-content: center;
     }
 }

 @media only screen and (max-width:770px) {
     .off_date_container {
         padding-left: 30px;
         text-align: unset;
     }

     .off_sdate_container {
         padding-left: 44px;
     }

     .off_month_container {
         padding-left: 20px;
         text-align: center;
     }

     .sdate_label {
         text-align: unset;
     }



     .book_content_container {
         margin-top: 30px;
     }
 }

 @media only screen and (max-width:750px) {
     .radio-search-container {
         justify-content: start;
     }
 }




 @media only screen and (max-width:750px) {
     .confrom_book_details_sub_container {
         padding-left: 40px;
         padding-right: 40px;
         padding-bottom: 40px;
         padding-top: 20px;
     }

     .confrom_book_subcontent_container {
         padding-left: 20px;
         padding: 15px;
     }
 }

 @media only screen and (max-width:700px) {
     .off_textarea {
         border-radius: 8px;

         background: #FFF;
         height: 60px;
         overflow-y: auto;
         max-width: 241px;
     }
 }

 @media only screen and (max-width : 600px) {
     .book_details_label_info_check {
         color: #484848;
         ;
         font-size: 17px;
         font-style: normal;

         line-height: normal
     }
 }

 @media only screen and (max-width:600px) {
     .book_details_head {
         font-size: 20px;
         font-weight: 700;
         line-height: 44px;
         letter-spacing: 2px;
         text-align: center;
         color: var(--ion-bold-text-color);
     }

     .book_content_container {
         margin-top: 20px;
     }
 }

 @media only screen and (max-width:560px) {
     .book_subcontent_container {
         padding: 10px;
         padding-left: 10px;
     }

     .book_details_sub_container {
         padding: 10px;
         padding-left: 40px;
         padding-right: 40px;
         padding-bottom: 40px;
     }

     .book_content_container {
         margin-top: 10px;
     }
 }


 @media only screen and (max-width: 545px) {
     .confrom_book_details_sub_container {
         padding-top: 5px;
         padding-left: 10px;
         padding-right: 10px;
         padding-bottom: 30px;
     }

     .tab_parent_div ion-text {
         font-size: 14px !important;
     }
 }

 @media only screen and (max-width:520px) {
     .book_subcontent_container {
         padding: 10px;
         padding-left: 10px;
     }

     .book_details_sub_container {
         padding: 10px;
         padding-left: 20px;
         padding-right: 20px;
         padding-bottom: 20px;
     }

     .book_poojaname_head {
         color: #222;
         ;
         font-size: 20px;
         font-style: normal;
         font-weight: 700;
         line-height: 43px;
         padding: 10px;
         padding-left: 30px;
     }

     .book_details_label_infoget {
         color: #484848;
         ;
         font-size: 17px;
         font-style: normal;

         line-height: normal
     }

     .book_details_label_date {
         color: darkgray;
         ;
         font-size: 15px;
         font-style: normal;

         line-height: normal
     }
 }


 @media only screen and (max-width:450px) {
     .book_subcontent_container {
         padding: 5px;
         padding-left: 10px;
     }

     .book_details_sub_container {
         padding: 10px;
         padding-left: 10px;
         padding-right: 10px;
         padding-bottom: 30px;
     }

     .book_details_head {
         font-size: 18px;
         font-weight: 600;
         line-height: 44px;
         letter-spacing: 2px;
         text-align: center;
         color: var(--ion-bold-text-color);
     }

     .login_alert_modal .modal-content {
         width: 80%;
         margin-left: 10%;
         margin-right: 10%;
         background-color: #F7F7F1;
     }

     .search-box {
         width: 100%;
     }

     .select-group {
         width: 100%;
     }

     .search-textbox {
         width: 100%;
     }

     .search-textbox-pooja {
         max-width: 100%;
     }

     .radio-search-container {
         justify-content: center;
     }
 }

 @media only screen and (max-width:415px) {
     .book_details_label_infoget {
         color: #484848;
         ;
         font-size: 15px;
         font-style: normal;

         line-height: normal
     }

     .back {
         font-size: 25px !important;
     }
 }

 @media only screen and (max-width:408px) {
     .confrom_book_subcontent_container {
         padding-left: 30px;
         padding: 5px;
     }
 }

 @media only screen and (max-width:397px) {
     .book_subcontent_container {
         padding-left: 0px;
         padding: 0px;
     }

     .accept_logo_off_info {
         width: 15px;
         height: 15px;
     }

     .book_details_head {
         font-size: 18px;
         font-weight: 600;
         line-height: 44px;
         letter-spacing: 2px;
         text-align: center;
         color: var(--ion-bold-text-color);
     }
 }

 @media only screen and (max-width:378px) {
     .off_date_container {
         padding-left: 15px;
         text-align: unset;
     }

     .off_sdate_container {
         padding-left: 44px;
     }

     .off_month_container {
         padding-left: 33px;
         text-align: center;
     }

     .sdate_label {
         text-align: unset;
     }
 }

 @media only screen and (max-width:350px) {
     .book_details_head {
         font-size: 16px;
         font-weight: 600;
         line-height: 44px;
         letter-spacing: 2px;
         text-align: center;
         padding-left: 8px;
         color: var(--ion-bold-text-color);
     }
 }

 @media only screen and (max-width:325px) {
     .book_details_head {
         font-size: 16px;
         font-weight: 600;
         line-height: 44px;
         letter-spacing: 2px;
         text-align: center;
         padding-left: 8px;
         color: var(--ion-bold-text-color);
     }
 }


 .login_alert_title {
     color: #404040;
     font-size: 20px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
 }

 .login_alert_modal .modal-content {
     width: 40%;
     margin-left: 30%;
     margin-right: 20%;
     background-color: #F7F7F1;
 }




 .border_change_clear {
     border: 1px solid gray;
     border-radius: 49px;
 }

 .modal_pooja_label {
     font-size: 18px;
     color: black;
 }

 .modal_pooja_name {
     font-size: 18px;
     color: black;
     font-weight: 700;
     padding-left: 5px;
 }

 .modal_head_column ion-row {
     align-items: center;
 }

 .off_grid_container {
     padding-top: 0px !important;
 }

 .off_chrg_container {
     padding-left: 38px;
 }

 .off_amt_input {
     font-weight: 700;
     max-width: 70px !important;
     height: 35px !important;
 }

 .charges-row {
     display: flex;
     align-items: center;
     gap: 10px;
 }

 .admin_controller_menu_container {
     gap: 5px;
 }

 .admin_controller_menu_container ion-col {
     display: flex;
     align-items: center;
     gap: 10px;
 }

 .con_book_details_label {
     color: #484848;
     ;
     font-size: 17px;
     font-style: normal;
     line-height: normal
 }

 .con_book_details_text {
     color: #222;
     font-size: 17px;
     font-weight: 700;
     padding-left: 20px;
     display: -webkit-box;
     --webkit-line-clamp: 5;
     -webkit-box-orient: vertical;
 }

 .disabled_textarea {
     width: 100%;
     overflow: auto;
     cursor: default;
     pointer-events: auto !important;
     background-color: ghostwhite;
     white-space: pre-line;
     user-select: none;
 }

 .undisabled_textarea {
     pointer-events: unset !important;
     background-color: inherit !important;
     white-space: pre-line;
 }