body {

  /* 
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */


  /* font: 500 20px/20px Roboto,Helvetica Neue,sans-serif !important; */



}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  white-space: normal !important;
}

.safe_area {
  padding-top: env(safe-area-inset-top);
  position: absolute !important;
  width: 100%;
  z-index: 1;
}

.in-toolbar {
  --ion-color-base: var(--ion-color-primary) !important;
}

ion-button.md.button.button-solid.ion-activatable.ion-focusable .button-native {
  /* background: #576F9F;
  background-color: #576F9F; */



}

.button_padding {
  padding: 10px 5px;
  font-size: 14px;
}

.button_padding_v2 {
  padding: 10px 10px;
  font-size: 14px;
}

ion-input,
ion-textarea,
ion-select,
ion-searchbar {
  color: #737272 !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 5px;
  /* font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  width: 346px;
height: 45px;
flex-shrink: 0;
border: 1px solid #E4E4E4 !important;
background-color: #F7F7F7 !important;
--background:#F7F7F7 !important;
; */
}

textarea {
  color: #737272 !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 5px;
  width: 100%;
  min-height: 60px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  outline: none !important;
}

ion-img {
  border: none !important;
}

ion-button {

  text-transform: none;
  font-size: 25px !important;
  margin-top: 5px;
  width: auto;
  height: 50px;
  flex-shrink: 0;

  font-style: normal;
  font-weight: 700;
  line-height: 43px;
  color: #FFF !important;
  --border-radius: 49px !important;
  fill: var(--ion-color-primary);

}


.btn_v2 {
  text-transform: none;
  font-size: 20px !important;
  margin-top: 5px;
  width: auto;
  height: unset !important;
  flex-shrink: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
  color: #FFF !important;
  --border-radius: 10px !important;
  fill: var(--ion-color-primary);

}

.cLrkOt {
  white-space: break-spaces !important;
}

.searchbardown {
  margin: 0%;
}

.ReactModal__Overlay {
  z-index: 1056 !important;
}

.admin_modal .modal-content {

  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 13px;
  /* width: 549px; */
  background: #FFF;
  margin: 0 auto;

}

.user-header-row input {
  display: none !important;
}

@media only screen and (max-width:550px) {
  .searchbardown {
    margin-top: 15%;
  }

  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

}

@media only screen and (max-width:900px) {
  .button_padding {
    padding: 5px;
    font-size: 18px;
  }
}

.inactive-tab {
  color: #ffffff;
  font-size: 15px;
}

ion-popover .inactive-tab {
  /* color: black; */
  font-size: 15px;
}

ion-popover .active-tab {
  /* color: black; */
  font-size: 15px;
}


/* @media (prefers-color-scheme: dark) {
  .inactive-tab_sub {
    color: white;
  }

  .active-tab_sub {
    color: #576F9F;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: var(--color);
  }

  input[type="time"]::-webkit-time-picker-indicator {
    filter: var(--color);
  }

  .profNum .PhoneInputInput,
  .signUpNum .PhoneInputInput {
    background-color: #121212;
    background: transparent;
  }

  .PhoneInputCountrySelect {
    color: white;
  }
  input.PhoneInputInput{
    background: transparent;
  }

  .alert-wrapper.sc-ion-alert-md {
    border: 1px solid whitesmoke;
  }

} */

@media (prefers-color-scheme: light) {
  .inactive-tab_sub {
    color: black;
  }

  .active-tab_sub {
    color: #576F9F;
  }
}

.high_align.in-toolbar {
  --ion-color-base: #a45b3b !important;
}

.high_align .link_page {
  color: #ffffff;

}

.headerToolbarCls {
  height: 100px;
}

.headerToolbarCls .headerMenubarCls,
.headerToolbarCls ion-row {
  height: 100px;
}

.IonPage {
  margin-top: '140px';

}

.centered-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vh;

}

.centered-modal .modal-content {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;


}

.gramerce-modal .modal-content {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

/* .login-modal .modal-content {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  height: fit-content;
} */

.login-modal .modal-content {
  padding-top: 65px;
  padding-left: 65px;
  padding-right: 45px;
  padding-bottom: 65px;
  border-radius: 13px;
  width: 549px;
  height: fit-content;
  background: #FFF;
  margin: 0 auto;
}

.login-modal .modal-body {
  padding: 0%;
}

.disableCursor {
  cursor: not-allowed !important;
}

.disableCursor_div {
  cursor: not-allowed !important;
  width: max-content;
}

.login-modal .profile-inputs {
  height: 45px;
}

.login-modal ion-button[type="submit"] {
  margin-top: 30px;
}

.gMzYpg {
  padding: 0px !important;


}

.image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

ion-searchbar {
  --color: black !important;
  --background: white !important;
  --placeholder-color: gray !important;
  --searchbar-input-color: black !important;

}

.image_design_deity {
  transition: transform 0.3s;
  box-shadow: 0px 0px 6px #d1d1d1;
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.PhoneInput {
  /* border: 1px solid #cbcbcb;
  max-width: 300px;
  height: 35px; */
  /* color: #737272 !important; */
  padding: 5px 10px;
  border: 1px solid var(--border-color) !important;

}

.PhoneInputInput:focus {
  outline: none;
  border: none;
}

.signUpNum {
  border: none;
  background: transparent;
  background-color: transparent;

}

.profNum {
  border: none;
  max-width: 300px;
  height: 27px;
  padding: 0px 5px;
  font-size: 14px;
}

.donationNum {
  border: 1px solid var(--border-color);
  max-width: 100%;
  height: 30px;
  padding: 0px 5px;
}

.PhoneInput .PhoneInputInput {
  border: none;
  background: transparent;


}

.donationNum .PhoneInputInput {
  border: none;
  background-color: white;
}


ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.zoomed-in .image_design_deity {
  transform: scale(1.0);
  width: 500px;
  height: 500px;
  box-shadow: 0px 0px 6px #d1d1d1;
}

.centered-modal-matmanagement .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* width: 100vh; */
  margin: 2% auto;
}

.centered-modal-matmanagement .modal-content {
  width: 60%;
  /* margin-left: 20%; */
  /* margin-right: 20%; */
}

@media only screen and (max-width:500px) {
  .centered-modal-matmanagement .modal-content {
    width: 90%;
    /* margin-left: 20%; */
    /* margin-right: 20%; */
  }

  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }
}

.alert_input_bottom {
  color: red;
  font-size: 12px;
  display: flex;
  padding-top: 2px;
  justify-content: left
}

/* .logoImgCls {
  width: 250px;
  margin-top: -20px;
} */
table td {
  /* background: white  !important; */


}

/* table th:nth-child(2n) {
  background: #EEF1F4 !important;
  text-align: center;

}

table th:nth-child(2n+1) {
  background: #F3F6F9 !important;
  text-align: center;

}

.rdt_TableCol:nth-child(2n) {
  background: #EEF1F4 !important;

}

.rdt_TableCol:nth-child(2n+1) {
  background: #F3F6F9 !important;

} */

/* .rdt_TableRow:nth-child(2n+1) {
  background-color: white !important;
}

.rdt_TableRow:nth-child(even) {
  background-color: white !important;
}

.rdt_TableRow:nth-child(odd) {
  background-color: white !important;
} */

.rdt_TableRow:hover {

  background: #f0efef !important;
  cursor: pointer;

}

/* new UI */

ion-button {
  text-transform: none;
  cursor: pointer;
}

.media_text {
  font-size: 20px;
  font-weight: 400;
}

.footer_logo_size {
  height: 35px;
  width: auto;
}

.footer_text {
  font-size: 18px;
  color: #FFF;
  letter-spacing: 1.2px;
  cursor: pointer;
  margin-top: 2px;
}

.footer_text:hover {
  font-size: 18px;
  letter-spacing: 1.2px;
  cursor: pointer;
  margin-top: 2px;
  color: var(--ion-bold-text-color);
}

.high_align.in-toolbar {
  --ion-color-base: var(--ion-footer-color) !important;
}

.high_align .link_page {
  color: #ffffff;
}

.media_alignment_container {
  display: block;
  padding-left: 10px;
}

.termsAndCondition_link {
  text-decoration: underline !important;
  color: white !important;
}

.container1 {
  display: grid;
}

.followus_container {
  margin-top: 10px;
}

.logo_container {
  /* margin-left: 10px; */
}

.media_logo_container {
  margin-top: 10px;
  gap: 8px;
  align-items: center;
}

.followus_divider {
  width: 25px;
  min-height: 1px;
  background-color: var(--ion-bold-text-color);
}

.divider_container {
  /* margin-left: 20%; */
  display: block;
}

.font_icon {
  font-size: 25px;
  cursor: pointer;
  color: white;
}

.copy_font {
  font-size: 15px;
  font-weight: 400;
  color: #FFF;
  /* letter-spacing: 1.2px; */
}

.copy_font_hignlight {
  font-size: 15px;
  font-weight: 700;
  color: var(--ion-bold-text-color);
  letter-spacing: 1.32px;
  padding-left: 4px;
}

.alert-button.ion-focused {
  background: transparent !important;
}

/***************Admin Head *******************/
.admin_head_container {
  width: 100%;
  height: 70px;
  background-color: var(--ion-color-primary-contrast);
}

.admin_menu_css {
  float: right;
  font-size: 35px;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.admin_head_sub_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  padding-left: 280px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E7E7E7;
  position: relative;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
}

.admin_head_sub_container2 {
  padding-left: 300px;
  display: flex;
  align-items: center;
  left: 0;
  height: 50px;
  box-shadow: 0px 2px 4px 0px #0000000D;
  position: relative;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
  border-bottom: 1px solid #E7E7E7;
}

.mini-sidebar .admin_head_sub_container {
  padding-left: 10px;
  padding-right: 10px;
}

.mini-sidebar .admin_head_sub_container2 {
  padding-left: 30px;
  padding-right: 10px;
}

.admin_head_menu_button {
  float: right;
  color: var(--ion-color-primary) !important;
  /* width: 100%; */
  font-size: 27px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.sidebar_logo {
  /* width:100%; */
  background-image: url('/public/images/logo.png');
  background-size: 80% auto;
  background-repeat: no-repeat;
  height: 70px;
  /* margin-left: 50px; */
  /* margin-top: 15px; */
  background-position: center center;
}

.admin_head_menu_name {
  font-size: 20px;
  font-weight: 700;
  line-height: 44px;
  width: fit-content;
}

.admin_background_color {
  background: rgb(243, 243, 249);
}

.data_table {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px #0000000D;
  padding: 10px;
}


.admin_add_btn {
  --border-radius: 10px !important;
  font-size: 14px !important;
  height: 40px;
  margin-top: 0px;
  line-height: normal;
  font-weight: 400;
}

.admin_cencel_btn {
  --border-radius: 10px !important;
  font-size: 18px !important;
  height: 40px;
  margin-top: 0px;
  line-height: normal;
  font-weight: 600;
  color: var(--ion-color-primary) !important;

}

.table_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.table_search {
  width: 324px;
  height: 40px;
  border-radius: 5px;
  border: 0.5px solid #DBDDE0;
  background: #F3F3F9;
  text-align: left;
  align-items: center;
  color: #000 !important;
}

.table_search * {
  background: #F3F3F9 !important;
  box-shadow: none !important;
  /* color: #C0C4CB !important; */
  /* height: 35px; */
}

.admin_header_v2_pageTitle {
  display: flex;
  align-items: center;

}

/*********************************************/

@media only screen and (max-width:767px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .centered-modal .modal-content {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;


  }

  .gramerce-modal .modal-content {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .container1 {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .logo_container {
    margin-left: 0%;
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }

  .media_alignment_container {
    display: flex;
    margin-left: 10px;
    width: 60%;
  }

  .media_logo_container {
    margin-top: 10px;
    width: 100%;
  }

  .followus_container {
    margin-top: 10px;
    margin-left: 2%;
    width: 40%;
  }

  .divider_container {
    display: none;
  }

  .footer_logo_size {
    height: 50px;
    width: 200px;
  }

  .media_text {
    font-size: 20px;
    font-weight: 400;
  }

  .font_icon {
    font-size: 25px !important;
    cursor: pointer;
    color: white;
  }
}

@media only screen and (max-width:670px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .logo_container {
    margin-left: 0%;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .media_alignment_container {
    display: flex;
    margin-left: 5px;
    width: 60%;
    align-items: baseline;
  }

  .gramerce-modal .modal-content {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .modal-content {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

}


@media only screen and (max-width:560px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .logo_container {
    margin-left: 0%;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .media_alignment_container {
    display: flex;
    margin-left: 5px;
    width: 70%;
    align-items: baseline;
  }

  .footer_logo_size {
    height: 40px;
    width: 150px;
  }
}

@media only screen and (max-width:560px) {

  .logo_container {
    margin-left: 0%;
    width: 30%;
    /* display: flex;
    justify-content: flex-end;
    align-items: center; */
  }


  .media_alignment_container {
    display: flex;
    margin-left: 5px;
    width: 70%;
    align-items: baseline;
  }

  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

}

@media only screen and (max-width:490px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .footer_text {
    font-size: 15px;
    color: #FFF;
    letter-spacing: 1.2px;
    cursor: pointer;
    margin-top: 2px;

  }
}

@media only screen and (max-width:395px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .footer_text {
    font-size: 13px;
    color: #FFF;
    letter-spacing: 1.2px;
    cursor: pointer;
    margin-top: 2px;
  }
}



@media only screen and (max-width:460px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .logo_container {
    margin-left: 0%;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .media_alignment_container {
    display: flex;
    margin-left: 5px;
    width: 60%;
  }

  .media_logo_container {
    margin-top: 10px;
    margin-left: 0%;
    width: 100%;
  }

  .followus_container {
    margin-top: 10px;
    margin-left: 1%;
    width: 50%;
  }

  .media_text {
    font-size: 17px;
    font-weight: 400;
  }

  .font_icon {
    font-size: 17px !important;
    cursor: pointer;
    color: white;
  }
}

@media only screen and (max-width:434px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .container1 {
    display: grid;
    width: 100%;
    justify-content: center;
  }

  .media_alignment_container {
    display: flex;
    margin-left: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .logo_container {
    margin-left: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer_logo_size {
    height: 40px;
    width: 400px;
  }

  .media_logo_container {
    margin-top: 10px;
    margin-left: 5px;
    width: auto;
  }

  .followus_container {
    margin-top: 10px;
    margin-left: 1%;
    width: auto;
  }

  .font_icon {
    font-size: 20px !important;
    cursor: pointer;
    color: white;
  }
}

@media only screen and (max-width:360px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .footer_logo_size {
    height: 40px;
    width: 250px;
  }

  .media_text {
    font-size: 12px;
    font-weight: 100;
  }

  .button_padding {
    padding: 5px 5px;
    font-size: 14px;
  }

  .admin_head_menu_name {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
}

@media only screen and (min-width:900px) {

  .container_middle {
    text-align: center;
    padding-top: 200px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 200px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }

  .container_middles_layout {
    /* text-align: center; */
    padding-top: 20px;
    min-height: calc(100vh - 4px);
  }
}

@media only screen and (max-width:900px) {
  .container_middle {
    text-align: center;
    padding-top: 100px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 120px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }

  .container_middles_layout {
    /* text-align: center; */
    padding-top: 110px;
    min-height: calc(100vh - 4px);
  }
}

@media only screen and (max-width:800px) {
  .container_middle {
    text-align: center;
    padding-top: 100px;

    padding-left: 33%;
    padding-right: 33%;
    min-height: calc(100vh - 4px);
  }

  .container_middles {
    text-align: center;
    padding-top: 100px;
    padding-left: 7%;
    min-height: calc(100vh - 4px);


    padding-right: 8%;
  }

  .container_middles_layout {

    padding-top: 90px;
    min-height: calc(100vh - 4px);
  }

  .button_padding {
    padding: 5px 5px;
    font-size: 14px;
  }

  .image_content_spn {
    font-size: 14px;
  }

  ion-button {
    height: 40px;
  }

  .login-modal .modal-content {
    padding-top: 65px;
    padding-left: 65px;
    padding-right: 45px;
    padding-bottom: 65px;
    border-radius: 13px;
    width: 500px;
    height: fit-content;
    background: #FFF;
    margin: 0 auto;
  }

  .login-modal .modal-content {
    padding-top: 65px;
    padding-left: 65px;
    padding-right: 45px;
    padding-bottom: 65px;
    border-radius: 13px;
    width: 500px;
    height: fit-content;
    background: #FFF;
    margin: 0 auto;
  }

}



.cart_padding {
  padding: 20px 40px 0px;
}

.page_content_padding {
  padding-top: calc(145px + env(safe-area-inset-top)) !important;
  background: white;
  min-height: 100vh;
}

.backgroundorders {
  background: #F7F7F7;
}

.order_header {
  border-radius: 7px 7px 0px 0px;
  background: #DBDBDB;
  padding: 10px;
  margin: 0px;
}

.center_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deity_image_div {
  margin-top: 15px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #484848;
  margin-left: -150px;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ;
  margin-bottom: 0px;

}

.padding_search {
  padding-top: 30px;
  padding-bottom: 30px;
}

.modal-title {
  color: #404040;
  /* font-size: 30px; */
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-header {
  padding: 10px 20px;
  border-bottom: none !important;
}

.back {
  cursor: pointer;
  display: flex;
  color: var(--ion-bold-text-color);
}

/* ion-label{
  color: #484848;
  
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ;
} */

/* ion-checkbox {
  border: 1px solid var(--border-color);
  border-radius: 50%;
} */

ion-radio {
  border: 1px thin var(--border-color);
  border-radius: 50%;
}

/* ion-checkbox::part(container) {
  border-radius: 50%;
  border: 1px solid var(--border-color);
} */

@media (prefers-color-scheme: dark) {
  ion-checkbox {
    border: 1px solid var(--border-color);
    /* border-radius: 50%; */
    background-color: black !important;
  }

  .alert-radio-label.sc-ion-alert-md {
    color: black;
  }

  [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color: black;
  }

  input[type="date"] {
    filter: invert(1);
  }

  input[type="time"] {
    filter: invert(1);
  }
}

.close_icon {
  width: 30px;
  height: 30px;
  font-weight: 700;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.scroll_tab {
  overflow-y: auto;


}

@media only screen and (max-width:1000px) {
  .page_content_padding {
    padding-top: calc(120px + env(safe-area-inset-top)) !important;
    background: white;
    min-height: 100vh;
  }

  .safe_area {
    background-color: #4D5462;
  }

  .cart_padding {
    padding: 40px 40px 0px;
    padding-top: calc(150px + env(safe-area-inset-top));
  }
}

@media only screen and (max-width:1000px) {
  .modal-dialog {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .scroll_tab {
    overflow-y: auto;
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: var(--ion-color-primary) var(--ion-color-light);
    /* For Firefox */

  }
}

@media only screen and (max-width:550px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .login-modal .modal-content {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;

    width: 350px;

  }
}

@media only screen and (max-width:300px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .login-modal .modal-content {
    padding: 0px;

    width: 250px;

  }
}

@media only screen and (max-width:650px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .table_search {
    width: 250px;
  }

  .searchbar-input.sc-ion-searchbar-md {
    -webkit-padding-end: 0px;
    padding-inline-end: 0px;
  }

  .modal-title {

    font-size: 22px;

  }
}

@media only screen and (max-width:420px) {
  .admin_modal .modal-content {

    padding: 0px;
    border-radius: 13px;
    /* width: 549px; */
    background: #FFF;
    margin: 0 auto;

  }

  .table_btn_container>div:last-child {
    margin-top: 10px;
  }
}


/************************** Sidebar & Page Wrapper ***********************/

.sidebar {
  background-color: var(--ion-color-primary);
  border-right: 1px solid transparent;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 0px;
  /* transition: all 0.2s ease-in-out 0.2s; */
  width: 270px;
  z-index: 1001;
}

.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar-inner {
  height: 100%;
  transition: all 0.2s ease-in-out 0.2s;
}

.sidebar-menu {}


.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.sidebar-menu li a {
  color: #ABB9E8;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
  text-decoration: none;
}

.sidebar-menu li a:hover {
  color: #fff;
}

.sidebar-menu li.active a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}

.menu-title {
  color: #838FB9;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 8px 15px;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: 400;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
  color: white;
  text-decoration: none;
}

.mobile-user-menu {
  color: #fff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10;
}

.mobile-user-menu>a {
  color: #fff;
  padding: 0;
}

.mobile-user-menu a:hover {
  color: #fff;
}

.mobile-user-menu>.dropdown-menu {
  min-width: 130px;
}

.mobile-user-menu>.dropdown-menu>a {
  line-height: 23px;
}

.profile-rightbar {
  display: none !important;
  color: #ff9b44;
  font-size: 26px;
  margin-left: 15px;
}

.mobile_btn {
  display: none;
  float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
  margin-left: auto;
}

.sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  padding: 9px 10px 9px 50px;
  position: relative;
}

.sidebar-menu ul ul ul a {
  padding-left: 70px;
}

.sidebar-menu ul ul ul ul a {
  padding-left: 90px;
}

.sidebar-menu>ul>li {
  position: relative;
  cursor: pointer;
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform .15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  ;
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}

.sidebar-menu .menu-arrow:before {
  /* content: "\f3e5"; */
}

.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.datatable-activate-btn {
  width: 114px !important;
  height: 28px !important;
  --border-radius: 2px !important;

}

.datatable-activate-btn span,
.initiate-shipment-btn span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sidebar-menu ul ul a .menu-arrow {
  top: 6px;
}

/*offering order manage*/

.manage_order_btn {
  width: 140px !important;
  height: 28px !important;
  --border-radius: 2px !important;
}

.manage_order_btn span {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.sidebar-menu a {
  line-height: 1.3;
  transition: unset;
  -moz-transition: unset;
  -o-transition: unset;
  -ms-transition: unset;
  -webkit-transition: unset;
}

.sidebar-menu>ul>li>a {
  padding: 12px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}

.sidebar-menu li a>.badge {
  color: #fff;
}

/************ Page Wrapper **********/

.page-wrapper {
  left: 0;
  margin-left: 250px;
  padding-top: calc(100px + env(safe-area-inset-top)) !important;
  position: relative;
  transition: all 0.2s ease-in-out;
  /* min-height: 528px; */
}

.page-wrapper>.content {
  padding: 30px;
}

.page-wrapper.job-wrapper {
  margin-left: 0;
}

.searchbar-input {
  height: 35px;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  top: 10px;
}

.searchbar-input.sc-ion-searchbar-md {
  height: 35px;
}



@media only screen and (min-width: 1000px) {
  #toggle_btn {
    color: #999;
    float: left;
    font-size: 26px;
    line-height: 60px;
    padding: 0 10px;
  }

  .mini-sidebar .sidebar {
    width: 0px;
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 230px;
  }

  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }

  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }

  .mini-sidebar .menu-title a {
    visibility: hidden;
  }

  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }

  .modal-open.mini-sidebar .sidebar {
    z-index: 1001;
  }

  .mini-sidebar .sidebar .sidebar-menu ul>li>a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  .mini-sidebar .sidebar .sidebar-menu ul>li>a span.chat-avatar-sm {
    display: inline-block;
    opacity: 1;
  }

  .mini-sidebar .sidebar .sidebar-menu ul>li>a span.status {
    display: inline-block;
    opacity: 1;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
    display: inline;
    opacity: 1;
  }

  .mini-sidebar .sidebar .sidebar-menu>ul>li>a i {
    font-size: 30px;
    width: 30px;
  }

  .mini-sidebar .sidebar .sidebar-menu .submenu {
    display: none;
    transition: all 0.2s ease-in-out;
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li>a i {
    font-size: 24px;
    width: 20px;
  }

  .mini-sidebar .page-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mini-sidebar .sidebar-menu li.active a {
    background-color: transparent;
  }

  .mini-sidebar .admin_head_sub_container {
    padding-left: 20px;
    padding-right: 10px;
  }

  .mini-sidebar .admin_head_sub_container2 {
    padding-left: 30px;
    padding-right: 10px;
  }

  .mini-sidebar .container_middle_table {
    text-align: center;
    padding-top: 2%;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 2%;
    min-height: calc(100vh - 4px);
  }

  .container_middle_table {
    text-align: center;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 20px;
    padding-bottom: 2%;
    min-height: calc(100vh - 4px);

  }
}


@media only screen and (max-width: 1000.98px) {
  #toggle_btn {
    display: none;
  }

  .admin_head_sub_container {
    padding-left: 30px;
  }

  .admin_head_sub_container2 {
    padding-left: 35px;
  }

  .mobile_btn {
    display: block;
  }

  .sidebar .sidebar-menu>ul>li>a span {
    margin-left: 0px;
  }

  .page-title-box {
    display: none;
  }

  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }

  .mobile_sidebar {
    background-color: var(--ion-color-primary);
    width: 100%;
    height: 100%;
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .header .has-arrow .dropdown-toggle>span:nth-child(2) {
    display: none;
  }

  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }

  .container_middle_table {
    text-align: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2%;
    min-height: calc(100vh - 4px);

  }
}

.right_col {
  display: flex;

  justify-content: right;

}

.left_col {
  display: flex;

  justify-content: left;
  width: 100%;
  text-align: left;

}

.center_col_tab {
  display: flex;
  justify-content: left;
  width: 100%;
  text-align: left;

}

.initiate-shipment-btn {
  width: 160px !important;
  height: 28px !important;
  --border-radius: 2px !important;
}

.admin_search_label {
  font-Weight: 400;
  font-size: 16px;
  line-height: 32.13px;
}

.admin_search_input {
  background-color: white;
  border-radius: 8px;
  height: 38px;
}

.image_div {
  border: 1px solid grey;
  padding: 0px;
  margin: 10px 0px;
}

.deity_image_div .image_div {
  border: 1px solid var(--border-color);
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
}

.image_content {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
}

.image_content_spn {
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin_img_btn {
  --border-radius: 0px !important;
  --border-top-left-radius: 10px !important;
  --border-bottom-left-radius: 10px !important;
  --border-top-right-radius: 0px !important;
  --border-bottom-right-radius: 0px !important;
  font-size: 18px !important;
  height: 40px;
  margin-top: 0px;
  line-height: normal;
  font-weight: 400;
}

.custom-alert {
  ion-alert {
    --background: rgba(0, 0, 0, 0.5);
    /* Adjust the background color */
    z-index: 2050;
    /* Adjust the z-index */

  }
}

input:read-only,
textarea:read-only {
  color: black !important;
  pointer-events: none;
}

input[type="checkbox"] {
  pointer-events: unset !important;
}

input[type="checkbox"]:disabled {
  display: none !important;
  cursor: default;
}


@media only screen and (max-width:800px) {
  .datatable-activate-btn {
    width: 100px !important;
    height: 28px !important;
    --border-radius: 2px !important;
  }
}

.parent {
  position: relative;

}

.child {
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 20px;


}

.PhoneInputCountrySelect[readonly] {
  pointer-events: none;
}

.ril__caption,
.ril__toolbar {
  background-color: transparent !important;
}

.item-radio-checked.sc-ion-select-popover-md {
  --background: none !important;
}

.date_icon_style {
  pointer-events: none;
}

.ios_radio_btn ion-radio {
  border: 1px solid var(--border-color) !important;
  border-radius: 50% !important;
  height: 22px !important;
  width: 22px !important;
}

.text-hyper {
  text-decoration: underline;
  color: blue;
  cursor: pointer;

}

/* HorizontalScrollList.css */
.horizontal-scroll-list-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scroll-list {

  display: flex;
  overflow-x: auto;
  white-space: nowrap;

}

.horizontal-scroll-list div {
  flex: 0 0 auto;
  width: 24%;
  margin-right: 1%;
  /* Adjust the margin as needed */
}

.horizontal-scroll-item {
  cursor: pointer;
  flex: 0 0 auto;
  min-width: 120px;
  /* Set a minimum width for each item */

  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ion-alert {
  text-align: center;
  /* text-align-last: center; */

}

ion-alert .alert-message {
  text-align: center;
  /* text-align-last: left; */
  text-wrap: wrap;
  white-space: normal !important;
}

.policy_text {
  text-align: left;
  margin: 3px 5px;
  text-align: justify;
}

.common_dataTable .rdt_TableCell div {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  /* Set the number of lines before truncation */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.common_dataTable .rdt_TableBody {
  max-height: 300px !important;
  overflow-y: auto !important;
  display: block !important;
}

.rdt_TableHead {
  min-height: 50px !important;
}

.select-disabled {
  color: black !important;
}